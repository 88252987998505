import React from "react";
import axios from "axios";

import {API_DOMEN} from "../../../../api";

import {ShopPageTextFormForm} from "../../../";

const ShopPageTextForm = ({
    typeForm,
    idAwo,
    title,
    description,
    btnText,
    tagIdAwo,
    redirectFormBotId,
    redirectFormBotName,
    awoFormCustom,
    awoFormCustomUrlScript,
    awoFormCustomId,
}) => {
    const [isSend, setIsSend] = React.useState(false);

    const onSubmit = (data) => {
        const utm_partner = parseInt(localStorage.getItem("utm_partner"));
        const utm_source = localStorage.getItem("utm_source");
        const utm_medium = localStorage.getItem("utm_medium");
        const utm_campaign = localStorage.getItem("utm_campaign");
        const utm_content = localStorage.getItem("utm_content");
        const utm_term = localStorage.getItem("utm_term");

        axios
            .post(
                typeForm === "timetable"
                    ? `${API_DOMEN}/goods/subsEmail`
                    : `${API_DOMEN}/goods/getsite`,
                {
                    ...data,
                    message: "",
                    tagIdAwo: tagIdAwo,
                    idAwo: idAwo,
                    utm_partner,
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_content,
                    utm_term,
                }
            )
            .then(() => {
                if (redirectFormBotId && redirectFormBotName) {
                    window.location.href = `https://t.me/${redirectFormBotName}?start=${btoa(
                        `${data.email}|${redirectFormBotId}`
                    )}`;
                } else {
                    setIsSend(true);
                }
            });
    };

    React.useEffect(() => {
        if (awoFormCustom == "1") {
            document
                .querySelector("#awo-form")
                .setAttribute("src", awoFormCustomUrlScript);
        }
    }, []);

    return (
        <section className={`shop-page-text-form`} id="text-form">
            <div className="container">
                <div className="shop-page-text-form-wrapper">
                    <div className="shop-page-text-form-text">
                        {title ? (
                            <h1
                                className={`shop-page-text-form-text__title`}
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}></h1>
                        ) : null}

                        <p
                            className={`shop-page-text-form-text__description`}
                            dangerouslySetInnerHTML={{__html: description}}></p>
                    </div>

                    {isSend ? (
                        <div className="shop-page-form-thank">
                            <h3 className="shop-page-form-thank__title">
                                Спасибо за заявку!
                            </h3>

                            <p className="shop-page-form-thank__description">
                                С вами скоро свяжется наш менеджер
                            </p>
                        </div>
                    ) : awoFormCustom == "1" ? (
                        <button
                            onClick={() =>
                                window.awoOpenFormModal(awoFormCustomId)
                            }
                            className="btn-bold_color shop-page-form__link">
                            {btnText}
                        </button>
                    ) : (
                        <ShopPageTextFormForm
                            onSubmit={onSubmit}
                            btnText={btnText}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default ShopPageTextForm;
